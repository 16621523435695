import { Fragment, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import ReactTooltip from "react-tooltip";
import Decimal from "decimal.js-light";

import { shallow } from "zustand/shallow";

import {
  formatToCurrency,
  formatToInteger,
  formatToFloat,
} from "../../../../commonjs/langutils";
import { sortProducts } from "../commonCalculations";

import { NonStandardProductContext } from "./components/NonStandardProductContext";
import { useShoppingCart } from "../ShoppingCart";

import { PrintService } from "./printService/PrintService";
import { DetailImage } from "../StandardProducts/SpecialColumns";
import { formatDescription } from "../StandardProducts/StandardProducts";

import { useModal, CRITICAL } from "../../modals/useModal";
import { NonActivatedOrdersMessage, PendingValue } from "../utils";
import { useDraftOrder } from "../useDraftOrder";

export const RowHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="thead">
      <div className="tr-head">
        {/* delete button */}
        <span className="th" />
        {/* image */}
        <span className="th" />
        <span className="th">{t("product")}</span>
        <span className="th">{t("details")}</span>
        <span className="th">{t("No. of labels")}</span>
        <span className="th">{t("No. of rolls")}</span>
        <span className="th">{t("price")}</span>
      </div>
    </div>
  );
};

export const NonStandardRows = (props) => {
  const ctx = useContext(NonStandardProductContext);

  return (
    <div className="tbody">
      {ctx.rows.map((row) => {
        return <NonStandardRow key={row.rowId} rowId={row.rowId} />;
      })}
    </div>
  );
};

const Column = ({ children }) => {
  return <span className="td">{children}</span>;
};
const DeleteRowButton = ({ deleteRow }) => {
  const { t } = useTranslation();

  return (
    <Column>
      <div
        className="icon-minus"
        onClick={deleteRow}
        data-for="deleteEntry"
        data-tip={t("Delete entry")}
      />
      <ReactTooltip
        id="deleteEntry"
        place="right"
        effect="solid"
        className="tooltip-info"
      />
    </Column>
  );
};

const selectStyles = {
  container: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
    width: "98%",
    border: "none",
    backgroundColor: "white",
  }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    backgroundColor: "white",
  }),
};

const ProductSelection = ({ handleChangeProduct, selectedOption }) => {
  const { t } = useTranslation();
  const { products, blockedDueToNonActivatedProducts } = useContext(
    NonStandardProductContext
  );

  const selectFieldProducts = sortProducts(products)
    .filter((p) => p.extra_services.length !== 0)
    .map((p) => {
      const {
        pk,
        customer_reference,
        destination,
        product_group,
      } = p;
      const description = formatDescription(t, p);
      const label = `${product_group.name} | ${customer_reference} | ${description} | ${destination}`;
      const value = customer_reference;
      return { label, value, pk };
    });

  return (
    <span className="td">
      <Select
        clearable={false}
        isSearchable={false}
        isDisabled={blockedDueToNonActivatedProducts}
        name="product-selection"
        value={selectedOption}
        placeholder={t("Choose product")}
        onChange={handleChangeProduct}
        options={selectFieldProducts}
        styles={selectStyles}
      />
    </span>
  );
};

const PrintingServiceIndicator = ({ hasPrintingServices, description }) => {
  const { t } = useTranslation();

  return (
    <span className="td">
      {description}
      {hasPrintingServices ? t(" with printing") : ""}
    </span>
  );
};

const NumberOfLabelsSelector = ({
  nrOfLabels,
  hasPrintData,
  handleChangeQuantity,
  selectedProduct,
  blocked,
}) => {
  const { t } = useTranslation();
  const [WarningDialog, openWarningDialog] = useModal({ level: CRITICAL });

  const tryingToOrderStandardProducts = selectedProduct
    ? nrOfLabels > 0 &&
    Number.isInteger(nrOfLabels / selectedProduct.labels_per_unit) &&
    !hasPrintData
    : false;

  return (
    <Column>
      <input
        style={{ width: "7em" }}
        type="number"
        className="numLabels"
        value={nrOfLabels > 0 ? nrOfLabels : ""}
        onChange={(e) => handleChangeQuantity(Number(e.target.value))}
        disabled={!selectedProduct || blocked}
        min={0}
      />

      {blocked && (
        <Fragment>
          <WarningDialog>
            <NonActivatedOrdersMessage />
          </WarningDialog>
          <span
            onClick={openWarningDialog}
            className="icon icon-error icon-error-position"
          >
            !
          </span>
        </Fragment>
      )}

      <span
        className="attention"
        style={tryingToOrderStandardProducts ? {} : { display: "none" }}
        data-for="tryingToOrderStandardProducts"
        data-tip={t(
          "Use this form only to order either a partial roll or to add customised printing data.<br />For ordering complete rolls without individual imprint please use the tab ‘STANDARD PRODUCTS’ only."
        )}
      >
        i
      </span>
      <ReactTooltip
        id="tryingToOrderStandardProducts"
        place="right"
        effect="solid"
        multiline={true}
        className="tooltip-warning"
      />
    </Column>
  );
};

const NumberOfRolls = ({ nrOfRolls }) => {
  const { t, language } = useTranslation();

  // split text to get around a translations scripts bug
  const partialRollText = [
    t("The chosen number of labels requires the delivery of a partial roll."),
    t("<br>This causes additional handling costs."),
    t("<br><br>To avoid these costs please order full rolls."),
  ].join("");

  const hasPartialRolls = !Number.isInteger(nrOfRolls);
  const formmattedNumber =
    nrOfRolls > 0
      ? Number.isInteger(nrOfRolls)
        ? formatToInteger(nrOfRolls, language)
        : formatToFloat(nrOfRolls, language, 4)
      : "";

  return (
    <span className="td">
      {formmattedNumber}
      <span
        className="attention"
        style={hasPartialRolls ? {} : { display: "none" }}
        data-for="partialRoll"
        data-tip={partialRollText}
      >
        i
      </span>
      <ReactTooltip
        id="partialRoll"
        place="right"
        effect="solid"
        multiline={true}
        className="tooltip-warning"
      />
    </span>
  );
};

const RowPrice = ({ price, isCalculating }) => {
  const { language } = useTranslation();
  return (
    <span className="td">
      <PendingValue isPending={isCalculating}>
        {formatToCurrency(price.toString(), language)}
      </PendingValue>
    </span>
  );
};

export const NonStandardRow = ({ rowId }) => {
  const { dispatchRowEvent } = useContext(NonStandardProductContext);
  const { changeQuantity, removeEntry, shoppingCart } = useShoppingCart(
    (state) => ({
      changeQuantity: state.changeQuantity,
      shoppingCart: state.shoppingCart,
      removeEntry: state.removeEntry,
    }),
    shallow
  );

  const { t } = useTranslation();
  const { products, blockedDueToNonActivatedProducts } = useContext(
    NonStandardProductContext
  );
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  const { isCalculating, findOrderPosition } = useDraftOrder();
  const [pending, setPending] = useState(false);
  useEffect(() => {
    if (!isCalculating) {
      setPending(false);
    }
  }, [isCalculating]);

  const orderPosition = findOrderPosition(rowId);
  const currentRowPrice = orderPosition ? orderPosition.total : Decimal(0);

  const cartEntry = shoppingCart.find((entry) => entry.rowId === rowId);

  const nrOfLabels = cartEntry && cartEntry.quantity ? cartEntry.quantity : 0;
  const hasPrintData = cartEntry && cartEntry.printData !== undefined;

  const deleteSelf = () => {
    dispatchRowEvent({ type: "removeRow", payload: { rowId } });
    removeEntry(rowId);
  };

  const handleChangeProduct = (selectedProduct) => {
    dispatchRowEvent({ type: "removePrintingService", payload: { rowId } });
    removeEntry(rowId);
    setSelectedProduct(products.find((p) => p.pk === selectedProduct.pk));
  };
  const handleChangeQuantity = (newQuantity) => {
    if (newQuantity > 0) {
      setPending(true);
      changeQuantity(selectedProduct, newQuantity, rowId);
    } else {
      setPending(false);
      removeEntry(rowId);
    }
  };

  return (
    <div className="tr">
      <DeleteRowButton deleteRow={deleteSelf} />
      <div className="td">
        <DetailImage
          image={selectedProduct ? selectedProduct.image : undefined}
          // hack to fix the DetailImage CSS issues
          nonStandardRow={true}
        />
        &nbsp;
      </div>
      <ProductSelection handleChangeProduct={handleChangeProduct} />
      <PrintingServiceIndicator
        enabled={hasPrintData}
        description={
          selectedProduct
            ? `${selectedProduct.labels_per_unit} ${hasPrintData ? t("labels/roll with printing") : t("labels/roll")
            }`
            : ""
        }
      />
      <NumberOfLabelsSelector
        nrOfLabels={nrOfLabels}
        handleChangeQuantity={handleChangeQuantity}
        hasPrintData={hasPrintData}
        selectedProduct={selectedProduct}
        blocked={blockedDueToNonActivatedProducts}
      />
      <NumberOfRolls
        nrOfRolls={
          selectedProduct ? nrOfLabels / selectedProduct.labels_per_unit : 0
        }
      />
      <RowPrice price={currentRowPrice} isCalculating={pending} />
      <span className="formWrapper">
        {selectedProduct && nrOfLabels > 0 && (
          <PrintService product={selectedProduct} rowId={rowId} />
        )}
      </span>
    </div>
  );
};
