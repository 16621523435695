export const E_REGISTER_CASE = {
  NONE: "NONE",
  COFOR10_FIRST: "COFOR10_FIRST",
  COFOR10_NEXT: "COFOR10_NEXT",
};

// four modes:
// - COFOR6 (options for company profiles + create new)
// - COFOR6_RESTRICTED (only create new company profile)
// - COFOR6_UNKNOWN (only create new company profile)
// - COFOR10 (a given company profile for selection)
export const COFOR_MODES = {
  COFOR6: "COFOR6",
  COFOR6_RESTRICTED: "COFOR6_RESTRICTED",
  COFOR6_UNKNOWN: "COFOR6_UNKNOWN",
  COFOR10: "COFOR10",
};

// When the user registers a company that does not belong to an existing COFOR
// this happens, when a user selects
// - "None of the Above" (COFOR6) or
// - "Create new company profile..." (COFOR6_UNKNOWN, COFOR6_RESTRICTED)
export const UNKNOWN_COMPANY = "UNKNOWN_COMPANY";
