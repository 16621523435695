import { Fragment } from "react";
import { Field, Form } from "react-final-form";
import { RenderField } from "../../../commonjs/components";
import { normalizePhone } from "../utils";
import { useTranslation } from "react-i18next";

import {
  useRegistration,
  checkIsRegistrationOfAdditionalUser,
  checkIsKnownCompany,
} from "./useRegistration";
import { i18n } from "../../../i18next";
import { AutoSave } from "../../../commonjs/components/Autosave";
import { isEmail } from "../utils";

import { CONTACT_CATEGORIES } from "./useRegistration";

const validateContact = (props) => {
  let errors = {};

  const required = ["name", "phone", "email"];
  required.forEach((fieldName) => {
    if (!props[fieldName]) {
      errors[fieldName] = i18n.t("Required");
    }
  });

  if (props?.email && !isEmail(props.email)) {
    errors.email = i18n.t("Invalid email");
  }
  if (props?.phone && props.phone.length < 8) {
    errors.phone = i18n.t("Please provide at least 8 digits");
  }

  return errors;
};
/* Contact form part
   
    Registering an additional user for an already registered company: 
    - no fields can be edited

    provisioning, commercial
      COFOR_MODE_10: when the the company is found in the DB and , no fields can be edited
      COFOR_MODE_NONE: for unknown companies, all fields can be edited 

    invoice, delivery
      these addresses can not be provided by Stellantis, therefore they can always be edited
      all fields can be edited  
*/

const ContactFormPart = ({ contact }) => {
  const {
    addContact,
    removeContact,
    isRegistrationOfAdditionalUser,
    isKnownCompany,
  } = useRegistration((state) => ({
    addContact: state.addContact,
    removeContact: state.removeContact,
    coforMode: state.coforMode,
    isRegistrationOfAdditionalUser: checkIsRegistrationOfAdditionalUser(state),
    isKnownCompany: checkIsKnownCompany(state),
  }));

  // editing rules
  // - provisioning, commercial
  //   unknown company, first user: all fields can be edited
  //   known company, first user:   no field can be edited (entries are determined from ff)
  //   additional user:             no field can be edited
  //
  // - invoice, delivery
  //   unknown company, first user: all fields can be edited
  //   known company, first user:   all fields can be edited
  //   additional user:             no field can be edited

  const editingDisabled =
    isRegistrationOfAdditionalUser ||
    (isKnownCompany &&
      ["PROVISIONING", "COMMERCIAL"].includes(contact.category));

  let shouldValidate = () => {
    // For registration of additional users, contacts are not validated
    if (isRegistrationOfAdditionalUser) return false;

    // for registration of known companies (known company + first user => from the ff), only TS_INVOICE and TS_DELIVERY are validated
    // as PROVISIONING and COMMERCIAL contact might be empty in the ff
    if (
      isKnownCompany &&
      ["PROVISIONING", "COMMERCIAL"].includes(contact.category)
    )
      return false;

    // For unknown companies, all contacts are validated
    return true;
  };

  const { t } = useTranslation();

  const categoryMapping = {
    PROVISIONING: t("Provisioning contact"),
    COMMERCIAL: t("Commercial contact"),
    TS_INVOICE: t("Contact for invoices"),
    TS_DELIVERY: t("Contact for delivery notes"),
  };

  return (
    <Form
      // not using submit functionality, data is handled with AutoSave    
      onSubmit={() => { }}
      validate={shouldValidate() ? validateContact : () => { }}
    >
      {() => (
        <div>
          <AutoSave
            onValid={(values) => {
              addContact({ ...values, category: contact.category });
            }}
            onInvalid={(values) => {
              removeContact({ ...values, category: contact.category });
            }}
          />
          <div className="grid__item xs-12 vertical-spacing-half">
            <h6>{categoryMapping[contact.category]}</h6>
          </div>
          <div className="grid__item xs-12 md-4">
            <Field
              name={"name"}
              initialValue={contact.name || ""}
              placeholder={t("Name")}
              disabled={editingDisabled}
              component={RenderField}
              type="text"
            />
          </div>
          <div className="grid__item xs-12 md-4">
            <Field
              name={"phone"}
              initialValue={contact.phone || ""}
              placeholder={t("Phone")}
              disabled={editingDisabled}
              component={RenderField}
              type="text"
              normalize={normalizePhone}
            />
          </div>
          <div className="grid__item xs-12 md-4">
            <Field
              name={"email"}
              initialValue={contact.email || ""}
              placeholder={t("Email")}
              disabled={editingDisabled}
              component={RenderField}
              type="text"
            />
          </div>
        </div>
      )}
    </Form>
  );
};

export const Contacts = () => {
  const { t } = useTranslation();

  const { selectedCompany } = useRegistration((state) => ({
    selectedCompany: state.selectedCompany,
  }));

  const initialContacts =
    selectedCompany.contacts ||
    CONTACT_CATEGORIES.map((category) => ({
      category: category,
      name: "",
      phone: "",
      email: "",
    }));

  return (
    <Fragment>
      <div className="grid__item xs-12 vertical-spacing">
        <h3>{t("Contacts")}</h3>
      </div>
      {initialContacts.map((contact, id) => {
        return <ContactFormPart contact={contact} key={id} />;
      })}
    </Fragment>
  );
};
