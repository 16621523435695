import React, { useEffect } from "react";

import { endpoint } from "../../commonjs/endpoints";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

const ConfirmationInProgress = () => {
  const { t } = useTranslation();
  return <h1>{t("CONFIRMATION IN PROGRESS ...")}</h1>;
};

const ConfirmationSuccess = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="success">{t("CONFIRMATION SUCCESSFUL")}</h1>
      <section>
        {t("Thank you for confirming your account.")}
        <br />
        {t(
          "Please note that your account needs to be activated in the P-SL system before you can login to the platform."
        )}
        <br />
        <br />
        {t("You will receive an email after the final activation.")}
      </section>
    </div>
  );
};

const ConfirmationFailure = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="failed">{t("CONFIRMATION FAILED")}</h1>
      <section>
        {t(
          "The confirmation of your account failed due to an invalid/expired confirmation code."
        )}
        <br />
        {t("Please contact scribos® <customer-service@scribos.com>.")}
        <br />
      </section>
    </div>
  );
};

export const EmailConfirm = () => {
  const { code } = useParams();

  const {
    mutate: confirmAccount,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(() =>
    endpoint("accounts:api_confirmation", "POST", {
      body: { token: code },
    }).then((result) => {
      if (result.errors)
        return Promise.reject(
          new Error("Error when confirming email", result.errors)
        );
    })
  );

  useEffect(() => {
    confirmAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <section className="email-confirm">
      <div className="bg-login" />
      {isLoading && <ConfirmationInProgress />}
      {!isLoading && isSuccess && <ConfirmationSuccess />}
      {!isLoading && isError && <ConfirmationFailure />}
    </section>
  );
};
