import React from "react";
import { localSettings } from "../../settings";
import { useAuthentication } from "../../commonjs/AuthenticationContext";

export const AccountBox = (props) => {
  const { companyProfile, account } = useAuthentication();

  return (
    <div className="account-box right">
      <span className="account-box-line">{account?.email}</span>
      {!localSettings.accountsHideAccountBoxLine ? (
        <span className="account-box-line">
          COFOR6: {companyProfile?.cofor6}
        </span>
      ) : (
        <br />
      )}
      <span className="account-box-line">
        Account: {companyProfile?.cofor10}
      </span>
    </div>
  );
};
