import { Icon } from "../../../commonjs/icons";

export const LoadingIndicator = ({ loading }) => {
  if (loading) {
    return (
      <Icon icon={"spinner"} spin pulse style={{ marginRight: "0.5em" }} />
    );
  } else {
    return null;
  }
};
