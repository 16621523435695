import { create } from "zustand";
import { isEqual, isEmpty } from "lodash";
import { devtools } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { COFOR_MODES } from "./defines";
import { endpoint } from "../../../commonjs/endpoints";

export const CONTACT_CATEGORIES = [
  "COMMERCIAL",
  "PROVISIONING",
  "TS_DELIVERY",
  "TS_INVOICE",
];

const ADDRESS_CATEGORIES = [
  "BILLING",
  "DELIVERY",
];

const allObjectValuesSet = (obj) => {
  let allSet = false;
  if (!isEmpty(obj)) {
    allSet = Object.keys(obj).reduce(
      (acc, currentKey) => acc && !isEmpty(obj[currentKey]),
      true
    );
  }
  return allSet;
}

export const checkUserProvided = (state) => {
  return allObjectValuesSet(state.user);
};

const companyProfileComplete = (state) => {
  return allObjectValuesSet(state.company_profile);
};

const allRequiredAddressesProvided = (state) => {
  return isEqual(
    ADDRESS_CATEGORIES,
    state.addresses.map((a) => a.category).toSorted()
  );
};

const allRequiredContactsProvided = (state) => {
  // check if all categories are present
  if (!isEqual(
    CONTACT_CATEGORIES,
    state.contacts.map((c) => c.category).toSorted()
  )) {
    return false;
  }

  // We have to check different contact categories for different company configurations
  // - known company: check delivery and invoice contact only
  // - unknown company: check all contacts
  // Why the limited check for a known company?
  // First, a known company is a company selected from the company selector.
  // It is in the company selector because:
  // (a) the company is in the ff
  // (b) the company was created in a former registration
  // In both cases we check only delivery and invoice contact:
  // case (a) provisional and/or commercial contact might contain empty fields in the ff, therefore skip them
  // case (b) all contacts were checked when the company was created (as unknown company), so it is safe to skip them
  const categoriesToCheck = checkIsKnownCompany(state)
    ? ["TS_DELIVERY", "TS_INVOICE"]
    : CONTACT_CATEGORIES;

  const contactsToCheck = state.contacts.filter(
    (contact) => categoriesToCheck.includes(contact.category)
  );

  return contactsToCheck.reduce(
    (acc, current) => acc && allObjectValuesSet(current),
    true
  )
};

const atLeastOneProductCategorySelected = (state) => {
  // for an additional user, the quotas are not displayed
  return checkIsRegistrationOfAdditionalUser(state) ? true : state.quotas.length > 0;
};

export const registrationPreconditionsMet = (state) => {
  const preconditions = [
    checkUserProvided,
    companyProfileComplete,
    allRequiredAddressesProvided,
    allRequiredContactsProvided,
    atLeastOneProductCategorySelected,
  ];

  /* for debugging only
  console.log("user provided", checkUserProvided(state));
  console.log("companyprofile complete", companyProfileComplete(state));
  console.log("required addresses", allRequiredAddressesProvided(state));
  console.log("required contacts", allRequiredContactsProvided(state));
  console.log("category ok", atLeastOneProductCategorySelected(state));
  */

  return preconditions.reduce(
    (preconditionsMet, precondition) => preconditionsMet && precondition(state),
    true
  );
};

const register = ({ state, set }) => {
  const registration = {
    accountId: state.selectedCompany.company_profile.cofor10,
    registerCase: state.coforMode,
    user: state.user,
    company_profile: {
      ...state.company_profile,
      cofor6: state.selectedCompany.company_profile.cofor6 || state.cofor,
      cofor10: state.selectedCompany.company_profile.cofor10,
      company_reference:
        state.selectedCompany.company_profile.company_reference,
    },
    quotas: state.quotas,
    contacts: state.contacts,
    addresses: state.addresses,
  };

  endpoint("accounts:api_registration", "POST", { body: registration }).then(
    (result) => {
      if (result.errors) {
        set((state) => ({
          ...state,
          registrationErrors: result.errors,
          submitting: false,
          registrationCompleted: false,
        }));
      } else {
        set((state) => ({
          ...state,
          submitting: false,
          registrationCompleted: true,
        }));
      }
    }
  );

  return { ...state, submitting: true };
};

export const checkIsRegistrationOfAdditionalUser = (state) => {
  return state.coforMode === COFOR_MODES.COFOR10 || state.first_user === false;
};
export const checkIsCompaniesToSelectAvailable = (state) => {
  return state.coforOptions?.length > 0;
};

export const checkIsUnknownCompany = (state) => {
  return state.selectedCompany?.company_profile?.cofor10 === undefined;
};

export const checkIsKnownCompany = (state) => {
  return !checkIsUnknownCompany(state);
};

export const useRegistration = create(
  devtools((set) => ({
    user: {},
    coforOptions: [],
    selectedCompany: undefined,
    coforMode: undefined,
    company_profile: {},
    setCompanyProfile: (profile) =>
      set((state) => ({ ...state, company_profile: profile })),
    addresses: [],
    contacts: [],
    quotas: [],
    setQuotas: (quotas) => set((state) => ({ ...state, quotas: quotas })),
    addUser: (user) =>
      set((state) => ({ ...state, user, registrationErrors: undefined })),
    removeUser: () => set((state) => ({ ...state, user: undefined })),
    setCoforMode: (coforMode, cofor) =>
      set((state) => ({ ...state, coforMode, cofor })),
    addCoforOptions: (coforOptions) =>
      set((state) => ({ ...state, coforOptions })),
    addAddress: (address) =>
      set((state) => ({
        ...state,
        addresses: state.addresses
          .filter((a) => a.category !== address.category)
          .concat(address),
      })),
    removeAddress: (address) =>
      set((state) => ({
        ...state,
        addresses: state.addresses.filter(
          (a) => a.category !== address.category
        ),
      })),
    addContact: (contact) =>
      set((state) => ({
        ...state,
        contacts: state.contacts
          .filter((c) => c.category !== contact.category)
          .concat(contact),
      })),
    removeContact: (contact) =>
      set((state) => ({
        ...state,
        contacts: state.contacts.filter((c) => c.category !== contact.category),
      })),

    registerUnknownCompany: () =>
      set((state) => ({
        ...state,
        registrationErrors: undefined,
        selectedCompany: {
          prefill: {},
          company_profile: {},
          quotas: [],
          addresses: [],
          contacts: CONTACT_CATEGORIES.map((category) => ({
            category,
          })),
        },
        addresses: [],
        contacts: [],
        quotas: [],
        first_user: true,
        company_profile: {
          name: "",
          vat_identification_no: "",
          cofor6: "",
        },
      })),
    selectCompany: (company) =>
      set((state) => {
        if (!company) return state;

        const prefill = state.companiesPrefill?.options.find(
          (option) =>
            option.prefill.company_profile.cofor10 === company.cofor10 &&
            option.prefill.company_profile.cofor6 === company.cofor6
        ).prefill;

        return {
          ...state,
          registrationErrors: undefined,
          selectedCompany: prefill,
          quotas: prefill.quotas || [],
          addresses: prefill.addresses || [],
          contacts: prefill.contacts || [],
          first_user: prefill.first_user || false,
          company_profile: {
            name: prefill.company_profile.name,
            vat_identification_no:
              prefill.company_profile.vat_identification_no,
            cofor6: prefill.company_profile.cofor6,
          },
        };
      }),
    addCompaniesPrefill: (companiesPrefill) =>
      set((state) => ({ ...state, companiesPrefill })),
    submitting: false,
    registrationCompleted: false,
    register: () => set((state) => register({ state, set })),
  }))
);

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("RegistrationStore", useRegistration);
}
