import { useState } from "react";

import { Spinner } from "../../../commonjs/components";
import { useMutation } from "../../../commonjs/fetchHooks";
import { useNotifications } from "../../../commonjs/NotificationContext";

import { useModal } from "..//modals";
import { useNavigate } from "react-router-dom";

import {
  formatToCurrency,
  formatToInteger,
  formatToFloat,
} from "../../../commonjs/langutils";

import { useShoppingCart } from "./ShoppingCart";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";

import { named_urls } from "../../../routing/known_urls";
import { MESSAGE } from "../modals/useModal";

import { useDraftOrder } from "./useDraftOrder";

import { NoteIndicator } from "./StandardProducts/SpecialColumns";
import { getCostExplanation } from "./StandardProducts/OrderTotal";

const PaymentsHeader = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="grid">
        <div className="grid__item xs-12 sm-4">
          <header className="xs-12 line-active right">
            <div className="right">&nbsp;</div>
          </header>
        </div>
        <div className="grid__item xs-12 sm-4">
          <header className="xs-12 line-active right">
            <div className="right">&nbsp;</div>
          </header>
        </div>
        <div className="grid__item xs-12 sm-4">
          <header className="xs-12 line-active right">
            <div className="right">{t("PAYMENT")}</div>
          </header>
        </div>
      </div>
      {children}
    </div>
  );
};

const TableHead = () => {
  const { t } = useTranslation();

  const columns = [
    " ",
    t("reference"),
    t("destination/warehouse"),
    t("No. of rolls"),
    t("No. of labels"),
    t("price per 1000 labels"),
    t("price"),
  ];

  return (
    <thead>
      <tr>
        {columns.map((column, id) => (
          <th key={id}>
            <em className={column}>{column}</em>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const OrderPositionRow = ({ position }) => {
  const { language } = useTranslation();
  const { product, quantity, total, price } = position;
  const [ImageDialog, openImageDialog] = useModal({ level: MESSAGE });

  const no_of_rolls = (quantity / product.labels_per_unit).toFixed(2);
  const formattedNoOfRolls =
    no_of_rolls % 1 === 0
      ? formatToInteger(no_of_rolls, language)
      : formatToFloat(no_of_rolls, language, 2);

  return (
    <>
      <ImageDialog>
        <div className="modal--image">
          <img src={product.image} alt={"Product Details"} />
        </div>
      </ImageDialog>

      <tr>
        <td>
          <div className="cell open-modal">
            {" "}
            <div className="info-modal-icon" onClick={openImageDialog} />
          </div>
        </td>
        <td>
          <div className="cell">{product.customer_reference}</div>
        </td>
        <td>
          <div className="cell">{product.destination}</div>
        </td>
        <td>
          <div className="cell text-right">{formattedNoOfRolls}</div>
        </td>
        <td>
          <div className="cell text-right">
            {formatToInteger(quantity, language)}
          </div>
        </td>
        <td>
          <div className="cell text-right">
            {formatToCurrency(price, language)}
          </div>
        </td>
        <td>
          <div className="cell text-right">
            {formatToCurrency(total, language)}
          </div>
        </td>
      </tr>
    </>
  );
};


const CostsSummary = ({ order }) => {
  const { t, language } = useTranslation();

  const handlingCostExplanation = getCostExplanation(t, language, order.handling_costs_explanation);
  const deliveryCostExplanation = getCostExplanation(t, language, order.delivery_costs_explanation);

  return (
    <div className="order__sum xs-2 right">
      <div className="order__handling-costs">{t("HANDLING COSTS")}</div>
      <div style={{ display: "flex" }}>
        <div>{formatToCurrency(order.handling_costs, language)}</div>
        {handlingCostExplanation &&
          <div style={{ marginLeft: "0.5rem" }}>
            <NoteIndicator
              type={handlingCostExplanation.type}
              note={handlingCostExplanation.text}
            />
          </div>
        }
      </div>
      <br />
      <div className="order__delivery-costs">{t("DELIVERY COSTS")}</div>
      <div style={{ display: "flex" }}>
        <div>{formatToCurrency(order.delivery_costs, language)}</div>
        {deliveryCostExplanation &&
          <div style={{ marginLeft: "0.5rem" }}>
            <NoteIndicator
              type={deliveryCostExplanation.type}
              note={deliveryCostExplanation.text}
            />
          </div>
        }
      </div>
      <hr className="hr--secondary" />
      <div className="order__total">{t("TOTAL")}</div>
      <div>{formatToCurrency(order.total, language)}</div>
      <hr className="hr--total" />
    </div>
  );
};

const VolumeWarning = ({ shoppingCart }) => {
  const { t } = useTranslation();
  const volume = shoppingCart.reduce(
    (currentVolumne, cartEntry) => currentVolumne + cartEntry.quantity,
    0
  );

  return volume >= 1500000 ? (
    <h2 style={{ color: "red" }}>
      {t(
        "You ordered more than 1.5 Mio. labels: Please check your order and make sure that this is actually what you intended to do!"
      )}
    </h2>
  ) : null;
};

const SUBMIT_ORDER = (data) => ({
  method: "POST",
  endpoint: "orders:api_order_list",
  data: { body: { ...data } },
});

export const OrderSummaryPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const {
    deliveryDate,
    clearOrder,
    customerOrderNo,
    customerTransportation,
    shoppingCart,
    setWarnings,
  } = useShoppingCart((state) => ({
    shoppingCart: state.shoppingCart,
    clearOrder: state.clearOrder,
    deliveryDate: state.deliveryDate,
    customerOrderNo: state.customerOrderNo,
    customerTransportation: state.customerTransportation,
    setWarnings: state.setWarnings,
  }));

  const { account, companyProfile } = useAuthentication();

  const [order] = useState({
    user: account.pk,
    billing_address: companyProfile.billing_address.pk,
    delivery_address: companyProfile.delivery_address.pk,
    customer_order_no: customerOrderNo,
    desired_delivery_date: deliveryDate,
    transport_by_customer: customerTransportation,
    order_positions: shoppingCart.map((cartEntry) => ({
      uuid: cartEntry.rowId,
      product: cartEntry.product.pk,
      quantity: cartEntry.quantity,
      extra_service_data: cartEntry.extra_services,
    })),
  });

  const { draftOrder, isCalculating } = useDraftOrder();
  const [mutate] = useMutation(SUBMIT_ORDER);

  const [submitting, setSubmitting] = useState(false);

  const placeOrder = async () => {
    setSubmitting(true);
    const { data, error } = await mutate(order);
    if (error) {
      addNotification({
        title: t("Order placement"),
        message: t("The order placement failed - please try again."),
        level: "error",
      });
    } else {
      if (data.warnings && data.warnings.length > 0) {
        setWarnings(data.warnings);
      } else {
        setWarnings([]);
      }
      clearOrder();
      navigate(named_urls["orders:summary:thankyou"]);
    }
    setSubmitting(false);
  };

  if (isCalculating) {
    return <Spinner />;
  }

  const tableMobileWidth =
    window.innerWidth <= 768
      ? { minWidth: draftOrder.order_positions.length * 120 + 200 + "px" }
      : null;

  return (
    <div className="orderSummary">
      <PaymentsHeader />
      <div className="grid">
        <div className="grid__item xs-12 sm-12">
          <div className="table vertical-spacing">
            <table style={tableMobileWidth}>
              <TableHead />
              <tbody>
                {draftOrder &&
                  draftOrder.order_positions.map((position, id) => (
                    <OrderPositionRow position={position} key={id} />
                  ))}
              </tbody>
            </table>
          </div>
          {draftOrder && <CostsSummary order={draftOrder} />}
          <VolumeWarning shoppingCart={shoppingCart} />
        </div>
        <div
          onClick={placeOrder}
          className={`button button-primary${submitting ? "--disabled" : ""
            } right`}
          style={{ marginRight: "0px", minWidth: "160px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            {submitting && <div className="large-button-spinner"></div>}
            <div>{t("SEND ORDER")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};