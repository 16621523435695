import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { RenderField, Spinner } from "../../../commonjs/components";
import { useCountryOptions } from "../../../commonjs/components/useCountryOptions";
import {
  useRegistration,
  checkIsRegistrationOfAdditionalUser,
  checkIsKnownCompany,
} from "./useRegistration";
import { i18n } from "../../../i18next";
import { AutoSave } from "../../../commonjs/components/Autosave";


const validateAddress = (address) => {
  const required = ["city", "country_code", "postal_code", "street_address"];
  const errors = {};
  required.forEach((r) => {
    if (!address[r]) errors[r] = i18n.t("Required");
  });
  return errors;
};

const AddressFormPart = ({ fieldsDisable, addressDetailsDisable, address }) => {
  const { t } = useTranslation();
  const categoryMapping = {
    DELIVERY: t("Delivery address"),
    BILLING: t("Billing address"),
  };
  const { addAddress, removeAddress } = useRegistration((state) => ({
    addAddress: state.addAddress,
    removeAddress: state.removeAddress,
  }));

  const { countryOptions, loading } = useCountryOptions();
  if (loading) return <Spinner />;

  return (
    <div>
      <div className="grid__item xs-12">
        <h4>{categoryMapping[address.category]}</h4>
      </div>

      <div>
        <Form
          // not using submit functionality, data is handled with AutoSave
          onSubmit={() => {}}
          validate={validateAddress}
        >
          {({ handleSubmit }) => (
            <Fragment>
              <AutoSave
                onValid={(values) =>
                  addAddress({ ...values, category: address.category })
                }
                onInvalid={(values) =>
                  removeAddress({ category: address.category })
                }
              />

              <div className="grid__item xs-12 md-6">
                <Field
                  name={`street_address`}
                  initialValue={address.street_address || ""}
                  placeholder={t("Street Address")}
                  disabled={fieldsDisable}
                  component={RenderField}
                  type="text"
                />
              </div>

              <div className="grid__item xs-12 md-3">
                <Field
                  name={`postal_code`}
                  initialValue={address.postal_code || ""}
                  placeholder={t("Postal Code")}
                  disabled={fieldsDisable}
                  component={RenderField}
                  type="text"
                />
              </div>

              <div className="grid__item xs-12 md-3">
                <Field
                  name={`city`}
                  initialValue={address.city || ""}
                  placeholder={t("City")}
                  disabled={fieldsDisable}
                  component={RenderField}
                  type="text"
                />
              </div>

              <div className="grid__item xs-12 md-6">
                <Field
                  name={`country_code`}
                  initialValue={address.country_code || "FR"}
                  component="select"
                  disabled={fieldsDisable}
                  className="vertical-spacing"
                >
                  {countryOptions.map((countryOption) => (
                    <option key={countryOption.code} value={countryOption.code}>
                      {countryOption.name} ({countryOption.code})
                    </option>
                  ))}
                </Field>
              </div>

              <div className="grid__item xs-12">
                <Field
                  name={`address_details`}
                  initialValue={address.address_details || ""}
                  placeholder={t("Address details (Building, Desk, ...)")}
                  disabled={addressDetailsDisable}
                  component={RenderField}
                  type="text"
                />
              </div>
            </Fragment>
          )}
        </Form>
      </div>
    </div>
  );
};

export const Addresses = () => {
  const { t } = useTranslation();
  const { selectedCompany, isRegistrationOfAdditionalUser, isKnownCompany } =
    useRegistration((state) => ({
      selectedCompany: state.selectedCompany,
      isKnownCompany: checkIsKnownCompany(state),
      isRegistrationOfAdditionalUser:
        checkIsRegistrationOfAdditionalUser(state),
    }));

  const initialAddresses = ["DELIVERY", "BILLING"].map((category) => {
    return (
      selectedCompany.addresses?.find(
        (address) => address.category === category
      ) || { category: category }
    );
  });

  const checkEditable = (address) => {
    // editing rules
    // - delivery address:
    //   unknown company, first user: all fields can be edited
    //   known company, first user:   only address details can be edited
    //                                (the delivery address is determined by the ff, but not the address details)
    //   additional user:             no field can be edited
    //
    // - billing address:
    //   unknown company, first user: all fields can be edited
    //   known company, first user:   all fields can be edited
    //                                (the billing address is not in the ff, therefore we need it on registration)
    //   additional user:             no field can be edited

    const disabled = true;
    const enabled = false;

    // additional users cannot edit anything
    if (isRegistrationOfAdditionalUser) {
      return [disabled, disabled];
    }

    // known company (= from the ff) and first user
    if (isKnownCompany) {
      switch (address.category) {
        case "DELIVERY":
          return [disabled, enabled];
        case "BILLING":
          return [enabled, enabled];
        default:
          // fallback, which should never trigger
          return [enabled, enabled];
      }
    }

    // for unknown companies, everything is editable
    return [enabled, enabled];
  };

  return (
    <Fragment>
      <div className="grid__item xs-12 vertical-spacing">
        <h3>{t("Addresses")}</h3>
      </div>
      {initialAddresses.map((address, id) => {
        // billing addresses (all fields!) can not be edited, when we register an additional user
        const [fieldsDisable, addressDetailsDisable] = checkEditable(address);

        return (
          <AddressFormPart
            key={id}
            addressId={id}
            fieldsDisable={fieldsDisable}
            addressDetailsDisable={addressDetailsDisable}
            address={address}
          />
        );
      })}
    </Fragment>
  );
};
