import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useShoppingCart } from "../ShoppingCart";
import { useId } from "../../../../commonjs/useId";

import {
  TableHead,
  Cell,
  Number,
  NumberInput,
} from "../../../../commonjs/components/DataTable";

import { RewindFlag, NoteIndicator, DetailImage } from "./SpecialColumns";
import { BlockingMessages } from "../utils";
import {
  formatToCurrency,
  formatToInteger,
} from "../../../../commonjs/langutils";

import { ProductListContext } from "./ProductListContext";
import { useDraftOrder } from "../useDraftOrder";
import { useRepeatOrder } from "../useRepeatOrder";

const PRODUCT_OPTIONS = {
  PRODUCT_OPTION_RIBBON: "PRODUCT_OPTION_RIBBON"
}

export const formatDescription = (t, product) => {
  let description = `${product.label_length}x${product.label_width}mm`;
  if (product.description) {
    description += ` ${product.description}`;
  }
  if (product.product_options && product.product_options.includes(PRODUCT_OPTIONS.PRODUCT_OPTION_RIBBON)) {
    description += ` ${t("+ TT-ribbons")}`;
  }
  return description;
};

export const ProductListTableHead = () => {
  const { t } = useTranslation();
  // prettier-ignore
  const columns = [
    { name: "rewind-flag", displayName: "", title: t("Rewind") },
    { name: "info-modal", displayName: "", title: t("Click for additional information on this label") },
    { name: "REFERENCE", displayName: t("reference"), title: "", width: "12%" },
    { name: "DESCRIPTION", displayName: t("description"), title: t("Dimensions in mm"), width: "18%" },
    { name: "DESTINATION", displayName: t("destination/warehouse"), title: "", width: "30%" },
    { name: "ROLLS", displayName: t("No. of rolls"), title: "" },
    { name: "LABELS", displayName: t("No. of labels"), title: "" },
    { name: "PRICE", displayName: t("price"), title: "" }
  ];

  return <TableHead columns={columns} />;
};
export const ProductGroup = ({ products }) => {
  if (!(products && products.length > 0)) {
    return null;
  } else {
    return (
      <div className="table vertical-spacing">
        <h2>{products[0].product_group.name}</h2>
        <table>
          <ProductListTableHead />
          <tbody>
            {products.map((product) => (
              <ProductLine key={product.pk} product={product} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export const ProductLine = ({ product }) => {
  const rowId = useId();
  const { i18n, t } = useTranslation();
  const language = i18n.language;
  const [nrOfRolls, setNrOfRolls] = useState(0);
  const [linePending, setLinePending] = useState(false);

  const { changeQuantity, removeEntry, shoppingCart } = useShoppingCart(
    (state) => ({
      changeQuantity: state.changeQuantity,
      shoppingCart: state.shoppingCart,
      removeEntry: state.removeEntry,
    })
  );

  const { repeatableOrderPositions, removeRepeatOrderPosition } =
    useRepeatOrder((state) => ({
      repeatableOrderPositions: state.orderPositions,
      removeRepeatOrderPosition: state.removeOrderPosition,
    }));

  const { findProductEntry, isCalculating } = useDraftOrder();

  // This checks for prefill data
  useEffect(() => {
    if (repeatableOrderPositions.length > 0) {
      const productEntry = repeatableOrderPositions.find(
        (entry) => entry.product.pk === product.pk
      );
      if (productEntry && productEntry.quantity > 0) {
        // fill in the prefill data - it will be deleted below
        setNrOfRolls(productEntry.quantity / product.labels_per_unit);
        setLinePending(true);
        changeQuantity(product, productEntry.quantity, rowId);
      }
    }
    // eslint-disable-next-line
  }, []);

  // This removes prefill data once nrOfRolls is set to a value > 0
  useEffect(() => {
    if (repeatableOrderPositions.length > 0 && nrOfRolls > 0) {
      removeRepeatOrderPosition(product.pk);
    }
    // eslint-disable-next-line
  }, [nrOfRolls]);

  // This makes sure that every line manages its own spinner
  useEffect(() => {
    if (
      !isCalculating &&
      shoppingCart.find((entry) => entry.product.pk === product.pk)
    ) {
      setLinePending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalculating]);

  // This makes sure that the line resets itself once the shopping cart is empty
  useEffect(() => {
    if (shoppingCart.length === 0 && nrOfRolls > 0) {
      setNrOfRolls(0);
      setLinePending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCart]);

  const orderPosition = findProductEntry(product.pk);
  const price = orderPosition && nrOfRolls > 0 ? orderPosition.total : 0;
  const quantity =
    orderPosition && nrOfRolls > 0
      ? formatToInteger(orderPosition.quantity)
      : 0;

  const { pk, customer_reference, image, rewind_flag, note } = product;

  const productListContext = useContext(ProductListContext);
  const product_description = formatDescription(t, product);

  let blocked = false;
  let blockingReason = undefined;
  // Reasons to block the account
  // - multiple missing activations for a product
  // - unpaid bills
  // - inactivity
  if (productListContext.blockedDueToUnpaidBills) {
    blocked = true;
    blockingReason = BlockingMessages.UNPAID_BILLS;
  }
  if (productListContext.blockedDueToInactivity) {
    blocked = true;
    blockingReason = BlockingMessages.INACTIVITY;
  }
  if (productListContext.blockedDueToNonActivatedProducts) {
    blocked = true;
    blockingReason = BlockingMessages.NON_ACTIVATED_ORDERS;
  }

  const handleNrOfRollsChange = (newNrOfRolls) => {
    if (newNrOfRolls === 0) {
      removeEntry(rowId);
      setNrOfRolls(0);
      setLinePending(false);
    } else {
      setLinePending(true);
      changeQuantity(product, product.labels_per_unit * newNrOfRolls, rowId);
      setNrOfRolls(newNrOfRolls);
    }
  };
  return (
    <tr key={pk} className={productListContext.blocked ? "blocked" : null}>
      <td>
        <div
          className="cell open-modal"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div style={{ display: "inline-block", margin: "0 auto" }}>
            <RewindFlag rewindFlag={rewind_flag} />
            <NoteIndicator note={note} />
          </div>
        </div>
      </td>

      <td>
        <DetailImage
          image={image}
          showModal={productListContext.showModal}
          hideModal={productListContext.hideModal}
        />
      </td>
      <Cell value={customer_reference} role="customer-reference" />
      <Cell value={product_description} role="product-description" />
      <Cell
        value={product.destination}
        role="destination-warehouse"
        width="200px"
      />
      <NumberInput
        onChange={(e) =>
          handleNrOfRollsChange(e.target.value ? parseInt(e.target.value) : 0)
        }
        number={nrOfRolls}
        blocked={blocked}
        blockingReason={blockingReason}
        showModal={productListContext.showModal}
        hideModal={productListContext.hideModal}
      />
      <Number
        number={quantity}
        error={productListContext.ERROR}
        pending={linePending}
      />
      <Number
        number={formatToCurrency(price.toString(), language)}
        error={productListContext.ERROR}
        pending={linePending}
      />
    </tr>
  );
};
