/**
 * Automatically
 * - calls onvalid function with the formvalues, when the form is valid
 * - calls oninValid, when the form changes back to invalid
 *
 */

import { useState, useEffect } from "react";
import { FormSpy } from "react-final-form";
import equal from "fast-deep-equal";

export const AutoSave = ({ onValid, onInvalid }) => {
  const [lastValues, setLastValues] = useState();

  const Save = ({ valid, values }) => {

    useEffect(() => {
      if (valid) {
        if (!equal(lastValues, values)) {
          onValid(values);
          setLastValues(values);
        }
      }
      if (!valid && lastValues) {
        setLastValues(undefined);
        onInvalid(values);
      }
    }, [valid, values]);
    

    return null;
  };

  return (
    <FormSpy subscription={{ valid: true }}>
      {(props) => (
        <Save valid={props.valid} values={props.form.getState().values} />
      )}
    </FormSpy>
  );
};
