import { useTranslation } from "react-i18next";
import Decimal from "decimal.js-light";

import { formatToCurrency } from "../../../../commonjs/langutils";
import { useShoppingCart } from "../ShoppingCart";
import { useModal, CRITICAL } from "../../modals/useModal";
import { PendingValue } from "../utils";
import { useDraftOrder } from "../useDraftOrder";
import { NoteIndicator } from "./SpecialColumns";


export function getCostExplanation(t, language, explanation) {
  let result = null;
  if (explanation) {
    switch (explanation.code) {
      case "HANDLING_COSTS_INCLUDED_IN_LABEL_PRICE":
      case "DELIVERY_COSTS_INCLUDED_IN_LABEL_PRICE":
        result = {
          type: "info",
          text: t("Included in the label price."),
        };
        break;
      case "HANDLING_COSTS_ADDITIONAL":
        if (explanation.min_total_without_handling_costs < 0) {
          result = {
            type: "warning",
            text: t("Order triggers additional costs.")
          };
        } else {
          result = {
            type: "warning",
            text: t("Orders below {{total}} trigger additional costs.", {
              "total": formatToCurrency(explanation.min_total_without_handling_costs, language)
            })
          };
        }
        break;
      default:
        break;
    }
  }
  return result;
}


export const OrderTotal = () => {
  const { t, language } = useTranslation();
  const { shoppingCart } = useShoppingCart((state) => ({
    shoppingCart: state.shoppingCart,
  }));

  const [WarningDialog, showWarningDialog] = useModal({ level: CRITICAL });

  const { draftOrder, isCalculating, isCalculated } = useDraftOrder();

  // TODO Currently we need to deduct the total, as transportation is not yet decided
  const { total, handlingCosts } =
    !isCalculating && isCalculated && shoppingCart.length > 0
      ? {
        total: new Decimal(draftOrder.total).minus(
          new Decimal(draftOrder.delivery_costs)
        ),
        handlingCosts: new Decimal(draftOrder.handling_costs),
      }
      : { total: 0, deliveryCosts: 0, handlingCosts: 0 };

  const minOrderValueReached = total > 0;

  let handlingCostExplanation = null;
  if (!isCalculating && isCalculated) {
    handlingCostExplanation = getCostExplanation(t, language, draftOrder.handling_costs_explanation);
  }

  return (
    <div className="grid">
      <div className="order__sum grid__item xs-2 right">
        <div className="order__handling-costs">{t("HANDLING COSTS")}</div>
        <div style={{ display: "flex" }}>
          <div>
            <PendingValue isPending={isCalculating}>
              {formatToCurrency(handlingCosts, language)}
            </PendingValue>
          </div>
          {handlingCostExplanation &&
            <div style={{ marginLeft: "0.5rem" }}>
              <NoteIndicator
                type={handlingCostExplanation.type}
                note={handlingCostExplanation.text}
              />
            </div>
          }
        </div>
        <hr className="hr--secondary" />
        <div
          className={
            !minOrderValueReached && !isCalculating ? "text-error" : null
          }
        >
          <div className="order__total">{t("TOTAL")}</div>
          <div>
            <PendingValue isPending={isCalculating}>
              {formatToCurrency(
                // total price and handling costs are decimals,
                // therefore use "plus"
                total,
                language
              )}
            </PendingValue>
          </div>
          {!minOrderValueReached && !isCalculating && (
            <div
              className="icon icon-error error-modal-button left"
              style={{
                paddingTop: "0.5em",
              }}
            >
              <WarningDialog>
                {t("Minimum order quantity not reached.")}
              </WarningDialog>
              <span
                onClick={showWarningDialog}
                className="icon icon-error error-modal-cell-button"
              >
                !
              </span>
            </div>
          )}
        </div>
        <hr className="hr--total" />
      </div>
    </div>
  );
};
