import { useState, useRef } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { RenderField } from "../../../commonjs/components";
import { Disabled } from "../../../commonjs/components/Disable";
import { endpoint } from "../../../commonjs/endpoints";
import { i18n } from "../../../i18next";
import { useRegistration, checkUserProvided } from "./useRegistration";
import { AutoSave } from "../../../commonjs/components/Autosave";
import { LoadingIndicator } from "./LoadingIndicator";

import ReCAPTCHA from "react-google-recaptcha";

const validateAccountSelect = (values) => {
  const { cofor6, cofor10 } = values;
  const errors = {};
  if (!(cofor6 || cofor10)) {
    const selectAtLeastOne = i18n.t("Option 1 or Option 2 is required");
    errors.cofor6 = selectAtLeastOne;
    errors.cofor10 = selectAtLeastOne;
  }

  return errors;
};

export const AccountSelect = ({ disabled }) => {
  const { t } = useTranslation();
  const [cofor6, setCofor6] = useState("");
  const [cofor10, setCofor10] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountsFetched, setAccountsFetched] = useState(false);

  const captchaRef = useRef();

  const { addCoforOptions, addCompaniesPrefill, setCoforMode, userProvided } =
    useRegistration((state) => ({
      addCoforOptions: state.addCoforOptions,
      addCompaniesPrefill: state.addCompaniesPrefill,
      setCoforMode: state.setCoforMode,
      userProvided: checkUserProvided(state),
    }));

  const fetchAccounts = async () => {
    setLoading(true);

    const captchaResponse = await captchaRef.current.executeAsync();

    const query = cofor6 ? { q_cofor6: cofor6 } : { q_cofor10: cofor10 };
    endpoint("accounts:api_cofor_list", "GET", {
      query: { ...query, "g-recaptcha-response": captchaResponse },
    }).then((result) => {
      setCoforMode(result.mode, cofor6 || cofor10);
      addCoforOptions(
        result.options.map((x, index) => ({
          index,
          address: x.address,
          name: x.prefill.company_profile.name,
          cofor10: x.prefill.company_profile.cofor10,
          cofor6: x.prefill.company_profile.cofor6,
        }))
      );
      addCompaniesPrefill(result);
      setLoading(false);
      // You only get one chance to look up accounts: When you havent't found anything,
      // you cannot trigger a new search!!!
      setAccountsFetched(true);
    });
  };

  return (
    <Form
      onSubmit={(values) => console.log("Selecting Company")}
      validate={validateAccountSelect}
    >
      {({ pristine, invalid }) => (
        <Disabled disabled={accountsFetched}>
          <AutoSave
            onValid={(values) => {
              setCofor10(
                values.cofor10?.length > 10
                  ? values.cofor10.substring(0, 10)
                  : values.cofor10
              );
              setCofor6(
                values.cofor6?.length > 6
                  ? values.cofor6.substring(0, 6)
                  : values.cofor6
              );
            }}
            onInvalid={() => {
              setCofor10(undefined);
              setCofor6(undefined);
            }}
          />

          <ReCAPTCHA
            locale={i18n.language}
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            size="invisible"
          />

          <div className="grid__item xs-12 vertical-spacing">
            <h3>
              {t(
                "Option 1: Register a new company or choose from registered companies:"
              )}
            </h3>
            <h4>{t("Enter COFOR (6 first characters)")}</h4>
          </div>
          <div className="grid__item xs-12">
            <Field
              name="cofor6"
              placeholder={t("COFOR (6)")}
              value={cofor6}
              disabled={!pristine && cofor10}
              component={RenderField}
              type="text"
            />
          </div>
          <div className="grid__item xs-12 vertical-spacing">
            <h3>&mdash; {t("OR")} &mdash;</h3>
          </div>
          <div className="grid__item xs-12 vertical-spacing">
            <h3>{t("Option 2: Add a new user to an existing account:")}</h3>
            <h4>{t("Enter your company's account number")}</h4>
          </div>
          <div className="grid__item xs-12">
            <Field
              name="cofor10"
              placeholder={t("Account ID")}
              value={cofor10}
              disabled={!pristine && cofor6}
              component={RenderField}
              type="text"
            />
          </div>
          {!accountsFetched && (
            <div className="grid__item xs-12">
              <div
                className={`button button-primary ${
                  (cofor6 === undefined && cofor10 === undefined) ||
                  !userProvided ||
                  pristine ||
                  loading ||
                  invalid
                    ? "button-primary--disabled"
                    : null
                }`}
                onClick={fetchAccounts}
              >
                <LoadingIndicator loading={loading} />
                {t("Continue")}
              </div>
            </div>
          )}
        </Disabled>
      )}
    </Form>
  );
};
