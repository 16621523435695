import React, { Fragment } from "react";

import { Navigate } from "react-router-dom";
import { named_urls } from "../../routing/known_urls";
import { RegisterCompanyDetails } from "./register/RegisterCompanyDetails";
import { RegisterUserDetails } from "./register/RegisterUserDetails";

import { AccountSelect } from "./register/AccountSelect";
import { CompanySelector } from "./register/CompanySelector";

import { useTranslation } from "react-i18next";
import {
  useRegistration,
  checkIsCompaniesToSelectAvailable,
} from "./register/useRegistration";
import { SubmitRegistration } from "./SubmitRegistration";
import { COFOR_MODES } from "./register/defines";

const RegistrationError = () => {
  const { t } = useTranslation();

  // TODO This can be made much smarter, eg. collect the errors from the validations
  const { registrationErrors } = useRegistration((state) => ({
    registrationErrors: state.registrationErrors,
  }));

  // for now we display only the user profile error
  if (registrationErrors?.user_profile) {
    return (
      <div className="grid__item xs-12">
        <span className="form-error">
          {t("A company user with this email address exists already.")}
        </span>
      </div>
    );
  }
  return null;
};

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="grid__item xs-12">
      <span className="form-error">
        {t(
          "COFOR6 or Account ID not found - please reload the page and enter a valid COFOR6 or Account ID."
        )}
      </span>
    </div>
  );
};

export const RegisterForm = () => {
  const {
    selectedCompany,
    registrationCompleted,
    isCompaniesToSelectAvailable,
    isCompaniesToSelectRequired,
    allowEmptyCompaniesToSelect
  } = useRegistration((state) => ({
    selectedCompany: state.selectedCompany,
    registrationCompleted: state.registrationCompleted,
    isCompaniesToSelectAvailable: checkIsCompaniesToSelectAvailable(state),
    // when the user entered an account id (= cofor10), we need a companies to select from
    isCompaniesToSelectRequired: state.coforMode === COFOR_MODES.COFOR10,
    // for COFOR6_UNKNOWN, COFOR6_RESTRICTED one can only create a new company profile
    allowEmptyCompaniesToSelect: [COFOR_MODES.COFOR6_UNKNOWN, COFOR_MODES.COFOR6_RESTRICTED].includes(state.coforMode)
  }));

  return (
    <div className="grid">
      {/* this is just kept in, to make the css-selectors happy */}
      <form>
        {/* render the user details form part */}
        <RegisterUserDetails />

        <Fragment>
          <div className="grid__item xs-12">
            <hr />
          </div>
          <AccountSelect />
          {/* user entered an account id (= cofor10), check if there are companies to select */}
          {isCompaniesToSelectRequired && !isCompaniesToSelectAvailable && (
            <NotFound />
          )}
          {(isCompaniesToSelectAvailable || allowEmptyCompaniesToSelect) && <CompanySelector />}
          {/* render the company details form part */}
          {selectedCompany && <RegisterCompanyDetails />}
          {/* handle registration errors */}
          <RegistrationError />

          {selectedCompany && <SubmitRegistration />}
          {registrationCompleted && (
            <Navigate
              to={{
                pathname: named_urls["accounts:register_confirm"],
              }}
            />
          )}
        </Fragment>
      </form>
    </div>
  );
};

export const Register = () => {
  const { t } = useTranslation();
  return (
    <section className="register">
      <div className="bg-login" />
      <h1>{t("CREATE ACCOUNT")}</h1>
      <h2>{t("Please fill in the information concerning your account.")}</h2>
      <hr />
      <RegisterForm />
    </section>
  );
};
