export const Disabled = ({ disabled, children }) => {
  if (disabled) {
    return (
      <div style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
        {children}
      </div>
    );
  } else {
    return children;
  }
};
