import { Fragment } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useRegistration } from "./useRegistration";
import { COFOR_MODES, UNKNOWN_COMPANY } from "./defines";
import { AutoSave } from "../../../commonjs/components/Autosave";

export const CompanySelector = () => {
  const { t } = useTranslation();
  const { selectCompany, coforOptions, coforMode, registerUnknownCompany } =
    useRegistration((state) => ({
      selectCompany: state.selectCompany,
      cofor10: state.cofor10,
      coforMode: state.coforMode,
      coforOptions: state.coforOptions,
      registerUnknownCompany: state.registerUnknownCompany,
    }));

  return (
    <Fragment>
      <div className="grid__item xs-12 vertical-spacing">
        <h3>{t("Choose company details")}</h3>
      </div>

      {/* Cofor10 selection */}
      <div className="grid__item xs-12 vertical-spacing">
        <Form onSubmit={() => console.log("selecting company")}>
          {({ handleSubmit }) => (
            <Fragment>
              <AutoSave
                onValid={({ selectedCofor }) => {
                  if (selectedCofor === UNKNOWN_COMPANY) {
                    registerUnknownCompany();
                    return;
                  }

                  if (selectedCofor) {
                    const cofor10 = selectedCofor.split("-")[0];
                    const cofor6 = selectedCofor.split("-")[1];

                    selectCompany(
                      coforOptions.find(
                        (company) =>
                          company.cofor10 === cofor10 &&
                          company.cofor6 === cofor6
                      )
                    );
                  }
                }}
                onInvalid={() => selectCompany(undefined)}
              />

              <Field name="selectedCofor" component="select">
                <option value={""} disabled hidden>
                  {t("Please choose...")}
                </option>
                {coforOptions.map((x) => (
                  <option
                    key={`${x.cofor10}-${x.cofor6}`}
                    value={`${x.cofor10}-${x.cofor6}`}
                  >
                    {x.cofor10}, {x.name}, {x.address.street_address},{" "}
                    {x.address.postal_code} {x.address.city},{" "}
                    {x.address.country} ({x.address.country_code})
                  </option>
                ))}
                {coforMode === COFOR_MODES.COFOR6 && (
                  <option key={UNKNOWN_COMPANY} value={UNKNOWN_COMPANY}>
                    {coforOptions.length > 0
                      ? t("None of the above...")
                      : t("Create new company profile...")}
                  </option>
                )}

                {(coforMode === COFOR_MODES.COFOR6_RESTRICTED ||
                  coforMode === COFOR_MODES.COFOR6_UNKNOWN) && (
                  <option key={UNKNOWN_COMPANY} value={UNKNOWN_COMPANY}>
                    {t("Create new company profile...")}
                  </option>
                )}
              </Field>
            </Fragment>
          )}
        </Form>
      </div>
    </Fragment>
  );
};
