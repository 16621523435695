import React, { Fragment } from "react";
import { i18n } from "../../../i18next";

import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { RenderField, RenderPasswordField } from "../../../commonjs/components";
import { AutoSave } from "../../../commonjs/components/Autosave";
import { isEmail, isInvalidPassword, normalizePhone } from "../utils";
import { useRegistration } from "./useRegistration";


const validateUserDetails = (values) => {
  let errors = { user: {} };

  const required = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "password",
    "password_again",
  ];

  required.forEach((fieldName) => {
    if (!values.user || !values.user[fieldName])
      errors.user[fieldName] = i18n.t("Required");
  });

  if (values.user?.email && !isEmail(values.user.email))
    errors.user.email = i18n.t("Invalid email");
  if (values.user?.phone && values.user.phone.length < 8)
    errors.user.phone = i18n.t("Please provide at least 8 digits");
  if (values.user?.password) {
    const errorText = isInvalidPassword(values.user.password);
    if (errorText) errors.user.password = errorText;
  }
  if (
    values.user?.password_again &&
    values.user?.password_again !== values.user?.password
  ) {
    errors.user.password_again = i18n.t("Passwords do not match");
  }

  return errors;
};

export const RegisterUserDetails = () => {
  const { t } = useTranslation();

  const { addUser, removeUser } = useRegistration((state) => ({
    addUser: state.addUser,
    removeUser: state.removeUser,
    editingDisabled: state.editingDisabled,
  }));

  return (
    <div>
      <div className="grid__item xs-12 vertical-spacing">
        <h3>{t("Enter user details")}</h3>
      </div>
      <Form
        // not using submit functionality, data is handled with AutoSave
        onSubmit={() => {}}
        validate={validateUserDetails}
      >
        {() => (
          <Fragment>
            <AutoSave
              onValid={(values) => addUser(values.user)}
              onInvalid={() => removeUser()}
            />
            <div className="grid__item xs-12 md-6">
              <Field
                name="user.first_name"
                placeholder={t("First Name")}
                component={RenderField}
                type="text"
              />
            </div>
            <div className="grid__item xs-12 md-6">
              <Field
                name="user.last_name"
                placeholder={t("Last Name")}
                component={RenderField}
                type="text"
              />
            </div>
            <div className="grid__item xs-12 md-6">
              <Field
                name="user.email"
                placeholder={t("E-Mail")}
                component={RenderField}
                type="text"
              />
            </div>
            <div className="grid__item xs-12 md-6">
              <Field
                name="user.phone"
                placeholder={t("Phone")}
                component={RenderField}
                type="text"
                normalize={normalizePhone}
              />
            </div>
            <div className="grid__item xs-12 md-6">
              <Field
                name="user.password"
                placeholder={t("Password")}
                component={RenderPasswordField}
                type="password"
              />
            </div>
            <div className="grid__item xs-12 md-6">
              <Field
                name="user.password_again"
                placeholder={t("Repeat Password")}
                component={RenderPasswordField}
              />
            </div>
          </Fragment>
        )}
      </Form>
    </div>
  );
};
