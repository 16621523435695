import { useTranslation } from "react-i18next";
import {
  useRegistration,
  registrationPreconditionsMet,
} from "./register/useRegistration";

export const SubmitRegistration = () => {
  const { t } = useTranslation();

  const { canRegister, register, submitting } = useRegistration((state) => ({
    submitting: state.submitting,
    canRegister: registrationPreconditionsMet(state),
    register: state.register,
  }));

  return (
    <div className="grid__item xs-12">
      <button
        type="submit"
        disabled={!canRegister || submitting}
        onClick={(evt) => {
          evt.preventDefault();
          register();
          return false;
        }}
        className={`button button-primary ${
          !canRegister || submitting ? "button-primary--disabled" : "clickable"
        }`}
      >
        {t("SEND")}
      </button>
    </div>
  );
};
