import React from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import { i18n } from "../../i18next";
import { isEmail } from "./utils";

import { useMutation } from "react-query";
import { endpoint } from "../../commonjs/endpoints";

import { Form, Field } from "react-final-form";
import { RenderField } from "../../commonjs/components";


/* Password reset request form */
const validate_password_reset_request = (values) => {
  const errors = {};
  if (!values["email"]) errors["email"] = i18n.t("Required");
  if (values["email"] && !isEmail(values["email"]))
    errors["email"] = i18n.t("Invalid email");
  if (!values["account_id"]) errors["account_id"] = i18n.t("Required");
  return errors;
};

const PasswordResetRequestForm = () => {
  const { t } = useTranslation();
  const notify = (msg) => toast.success(msg, { duration: 5000 });

  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(({ email, account_id }) =>
    endpoint("accounts:api_reset_password", "POST", {
      body: { email, account_id },
    }).then((result) => {
      // We handle both the error and the regular case uniformly:
      notify(
        t("If a matching account was found an email was sent to {{email}} to allow you to reset your password.", { email })
      );
    })
  );

  const disabled = isLoading || isError || isSuccess;
  return (
    <Form
      initialValues={{}}
      onSubmit={(values) => resetPassword(values)}
      validate={validate_password_reset_request}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit} disabled={submitting || disabled}>
          <div className="grid">
            <div className="grid__item xs-12 md-8">
              <Field
                name="email"
                disabled={submitting || disabled}
                component={RenderField}
                type="text"
                placeholder={t("Email")}
              />
            </div>
            <div className="grid__item xs-12 md-8">
              <Field
                name="account_id"
                component={RenderField}
                disabled={submitting || disabled}
                type="text"
                placeholder={t("Account id")}
              />
            </div>
            <div className="grid__item xs-12 md-8">
              <button
                type="submit"
                disabled={pristine || submitting || disabled}
                className={`button button-primary ${pristine || submitting || disabled
                  ? "button-primary--disabled"
                  : "clickable"
                  }`}
              >
                {t("RESET")}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

/* Password reset request PAGE */
export const PasswordResetRequest = () => {
  const { t } = useTranslation();
  return (
    <section className="password-reset-request">
      <div className="bg-login" />
      <h1>{t("RESET YOUR PASSWORD")}</h1>
      <h2>{t("Please enter email address and account id")}</h2>
      <PasswordResetRequestForm />
    </section>
  );
};

