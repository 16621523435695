import React from "react";

import { useRegistration } from "./useRegistration";
import { CompanyProfile } from "./CompanyProfile";
import { Addresses } from "./Addresses";
import { Contacts } from "./Contacts";
import { Quotas } from "./Quotas";


export const RegisterCompanyDetails = () => {
  const { coforMode, coforOptions } = useRegistration((state) => ({
    coforOptions: state.coforOptions,
    coforMode: state.coforMode,
  }));

  if (!coforMode && !coforOptions.length > 0) {
    return null;
  }

  return (
    <div className="captchaComplete">
      <CompanyProfile />
      <Addresses />
      <Contacts />
      <Quotas />
    </div>
  );
};
