import { CountryCodesQuery } from "../../licensing/orders/order_process/Queries";
import { useQuery } from "react-query";

export const useCountryOptions = () => {
  const { data: countryCodes, isLoading } = useQuery(CountryCodesQuery);

  if (isLoading) {
    return { countryOptions: [], isLoading: true };
  } else {
    return {
      countryOptions: countryCodes.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
      loading: false,
    };
  }
};
