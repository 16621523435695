import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { Field } from "react-final-form";

import { RenderField } from "../../../commonjs/components";
import { i18n } from "../../../i18next";
import { AutoSave } from "../../../commonjs/components/Autosave";
import {
  useRegistration,
  checkIsRegistrationOfAdditionalUser,
  checkIsKnownCompany,
} from "./useRegistration";

const validateCompanyProfile = (values) => {
  const errors = {};

  if (!(values.name && values.name.length > 0)) {
    errors.name = i18n.t("Required");
  }
  if (
    !(values.vat_identification_no && values.vat_identification_no.length > 0)
  ) {
    errors.vat_identification_no = i18n.t("Required");
  }

  return errors;
};

export const CompanyProfile = () => {
  const { t } = useTranslation();
  const {
    company_profile,
    setCompanyProfile,
    isRegistrationOfAdditionalUser,
    isKnownCompany,
  } = useRegistration((state) => ({
    company_profile: state.company_profile,
    setCompanyProfile: state.setCompanyProfile,
    coforMode: state.coforMode,
    isRegistrationOfAdditionalUser: checkIsRegistrationOfAdditionalUser(state),
    isKnownCompany: checkIsKnownCompany(state),
  }));

  return (
    <Form
      // not using submit functionality, data is handled with AutoSave
      onSubmit={() => { }}
      validate={validateCompanyProfile}
    >
      {({ handleSubmit, pristine, submitting }) => (
        <div className="cofor10step">
          <AutoSave
            onValid={(values) => setCompanyProfile(values)}
            onInvalid={() => setCompanyProfile({})}
          />
          <div className="grid__item xs_12 md-12">
            <Field
              name="name"
              placeholder={t("Company Name")}
              disabled={isKnownCompany}
              component={RenderField}
              initialValue={company_profile.name}
              type={"text"}
            />
          </div>
          <div className="grid__item xs_12 md-12">
            <Field
              name="vat_identification_no"
              placeholder={t("VAT")}
              disabled={isRegistrationOfAdditionalUser}
              component={RenderField}
              initialValue={company_profile.vat_identification_no}
              type={"text"}
            />
          </div>
        </div>
      )}
    </Form>
  );
};
